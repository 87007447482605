export const STATUS_VALUE = {
  O: 'Observe',
  NT: 'Needs Treatment',
  No: 'Non Observable',
  S: 'Slight',
  M: 'Moderate',
  SV: 'Severe'
}

export const GetShortName = (name, splitOption) => {
  return name && `${name.split(splitOption).map((item) => item[0].toUpperCase()).join('')}`;
}

export const TAGS_COLOR_CODE = {
  C: '#FD636B',
  C1: '#FD636B',
  C2: '#FD636B',
  C3: '#FD636B',
  T: '#5fbadd',
  E: '#2EB670',
  GS: '#27507E',
  GR: '#FF7A59',
  BT: '#2196f3',
  G: '#ffc107'
  // PB: '#FF7A59',
}

export const TAGS_SHORT_NAME = {
  C: 'Cavities',
  C1: 'Cavities',
  C2: 'Cavities',
  C3: 'Cavities',
  T: 'Tartar',
  E: 'Enamel',
  GS: 'Gum Swollen',
  GR: 'Gum Recession',
  BT: 'Broken Teeth',
  G: 'Gaps'
  // PB: '#FF7A59',
}

export const VDC_TAGS_COLOR_CODE = {
  Cavity: '#FD636B',
  PB: '#FF7A59',
  DR: '#2EB670',
  GI: '#27507E',
  BT: '#3abfe2'
}

export const TAGS_KEYS = {
  cavities: 'Cavities',
  tartar: 'Tartar',
  gum_swollen: 'Gum Swollen',
  gum_recession: 'Gum Recession',
  enamel: 'Enamel',
  broken_teeth: 'Broken Teeth',
  gaps: 'Gaps'
}

// Should be cavity to 'Cavity'
export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const getTagName = (key, data) => {
  return key === 'cavity' ? data.type ? data.type : 'C1' : GetShortName(key, '_')
}

export function removeExtension(filename) {
  return (
    filename.substring(0, filename.lastIndexOf('.')) || filename
  );
}

export const getOriginalImageWidthAndHeight = (image) => {
  const i = new Image();
  i.src = image;
  return {
      width: i.width,
      height: i.height
  }
}