import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';

import ButtonLink from '../ReportList/Components/ButtonLink';
import { ButtonLinkData } from '../../utility/tab.utility';
import './../../stylesheet/common.css';

const ReportReviewedListTable = (props) => {
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10
    });
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState();
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const buttonLinkData = ButtonLinkData(user);

    useEffect(() => {
        setIsLoading(true);
        // if (props.reportListData?.length > 0) {
        //     setReportData(props.reportListData)
        // }
        setReportData(props.reportListData)
        setIsLoading(false);
    }, [props.reportListData]);

    useEffect(() => {
        if (!isFilterApplied) {
            props.handlePaginationData(pagination);
            setIsLoading(true);
        }
    }, [pagination.pageIndex, pagination.pageSize]);

    //defining columns outside of the component is fine, is stable
    const columns = [
        {
            accessorKey: '_id',
            header: 'ID',
            size: 120,
            Cell: ({ row }) => (
                <>
                    <AssignmentIcon className='primary-color' />
                    <span className='ms-2'>{row.original._id}</span>
                </>
            ),
        },
        // {
        //     header: 'Reviewer Name',
        //     Cell: ({ row }) => {
        //         return <div>{row.original?.name ? row.original.name : row.original?.reviewer?.first_name}</div>
        //     }
        // },
        {
            header: 'Annotator Name',
            Cell: ({ row }) => {
                return <div>{row.original?.annotator?.first_name}</div>
            }
        },
        {
            header: 'Annotated Date',
            Cell: ({ row }) => {
                return <div>{new Date(row.original?.createdAt).toLocaleDateString()}</div>
            }
        },
        {
            header: 'Reviewer name',
            Cell: ({ row }) => {
                return <div>{row.original?.reviewer?.first_name}</div>
            }
        },
        {
            header: 'Reviewed Date',
            Cell: ({ row }) => {
                return <div>{
                        row?.original?.reviewer ? new Date(Number(row.original?.reviewed_confirmed_date)).toLocaleDateString() : '-'
                    }</div>
            }
        },
        {
            header: 'Action',
            Cell: ({ row }) => {
                const stateData = isFilterApplied ?
                    {
                        _id: row.original?.report?._id,
                        name: row.original?.reviewer?.first_name,
                        email: row.original?.reviewer?.email,
                        ...row.original?.report
                    } : { ...row.original.report,
                            isThirdPartyReports: props?.isThirdPartyReports ? props?.isThirdPartyReports : false,
                            isGapsInFrontTeethReports: props?.isGapsInFrontTeethReports ? true : false
                        }
                return <Button onClick={() => navigate('/reviewer-feedback', {
                    state: {
                        data: row.original //stateData
                    }
                })}>view</Button>
            },
        }
    ];

    return (
        <>
            <MaterialReactTable
                columns={columns}
                data={reportData ?? []} //fallback to array if data is undefined
                // enableStickyHeader
                rowNumberMode="original"
                enableRowNumbers
                enablePagination={isFilterApplied ? false : true}
                rowCount={props.reportCount}
                manualPagination={true}
                onPaginationChange={setPagination}
                state={{ pagination, isLoading }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    {
                        buttonLinkData.map((item) => {
                            return <ButtonLink
                                key={item.btnLabel}
                                btnUrl={item.btnUrl}
                                btnLabel={item.btnLabel}
                                isActive={locationPath.pathname === item.btnUrl ? true : false}
                            />
                        })
                    }
                </Box>
                )}
            />
        </>
    )
};

export default ReportReviewedListTable;
