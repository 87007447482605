import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";

const HeaderNav = ({ title }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate('/')
    }

    const handleGoBack = () => {
        window.history.back();
    }

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar className='justify-content-center'>
                {
                    window.location.pathname !== '/report-list' && (
                        <div className="position-absolute left-0" onClick={handleGoBack}>
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <ArrowBackIosNewOutlined className="text-white" />
                            </IconButton>
                        </div>
                    )
                }
                <Typography variant="h6" noWrap component="div">{title}</Typography>
                <div className="position-absolute right-0" onClick={handleLogout}>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                        <ExitToAppIcon className="text-white" />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default HeaderNav;