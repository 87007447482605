import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";

const OPTIONS = ['missed detection', 'wrong detection']

const DetectionInfoSection = (props) => {
    const { handleDetectionInfoChanges, detectionInfoData = [] } = props;

    const handleChangeOption = (event, setFieldValue, optionValue) => {
        const { value, checked } = event.target;
            const newCheckedValues = checked
              ? [...optionValue, value] // Add value if checked
              : optionValue.filter((item) => item !== value); // Remove value if unchecked
            setFieldValue('detection_info', newCheckedValues); // Update Formik state
            handleDetectionInfoChanges(newCheckedValues)
    }

    return (
        <div className='my-1 d-flex align-items-center'>
            <label className='font-size-14 me-2'><b>Detection info:</b></label>

            <Formik
                initialValues={{
                    detection_info: [...detectionInfoData]
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>

                        <FormControl component="fieldset" variant="standard">
                            <FormGroup className='d-flex flex-row' role='group'>
                                {
                                    OPTIONS.map((item) => {
                                        return <FormControlLabel
                                        key={item}
                                        control={
                                            <Checkbox
                                                name="detection_info"
                                                onChange={(event) => handleChangeOption(event, setFieldValue, values.detection_info)}
                                                value={item}
                                                size="small"
                                                checked={values.detection_info.includes(item)}
                                            />
                                        }
                                        label={item}
                                    />
                                    })
                                }
                            </FormGroup>
                        </FormControl>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default DetectionInfoSection;