import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

import BasicCard from '../Cards/BasicCard';
import ImagePlaceholder from '../../../assets/image-placeholder1.svg';
import DetectionListForm from '../Forms/DetectionListForm';
import LegendsList from '../List/LegendsList';
import CloseIconButton from '../Button/CloseIconButton';
import ToolHeader from '../Header/ToolHeader';
import PositionedSnackbar from '../Snackbar/Snackbar';
import AlignerVideoIframe from '../Iframe/AlignerVideoIframe';
import { GetBucketFile, StoreJsonFile } from '../../../Services/reportService';
import { GetShortName, TAGS_COLOR_CODE, TAGS_KEYS, TAGS_SHORT_NAME, removeExtension } from './Utility';
import { API_RESPONSE, DEMO_DENTAL_DOCTOR_EMAIL, IMAGE_TAGS, TEETH_TYPES, USER_TYPE } from '../../../constants/common.constant';
import './Dialog.css';
import TagLabel from './common/TagLabel';
import { getAlignerUserById } from '../../../Services/alignerService';
import TestingZoomFeature from './common/TestingZoomFeature';
import { saveImageAnnotationData } from '../../../Services/admin.service';
import { getAssignedTagsAPI, postLogDetails } from '../../../Services/annotator.service';
import { checkTagsAccess, getReportType, getTagsListArray } from './reportDialog.helper';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

let imageTagsData = [];
let detectionListFormData = {
    // gaps_in_teeth: { upper_teeth: '', lower_teeth: '' },
    poor_alignment: { upper_teeth: [], lower_teeth: [] },
    dark_teeth: { upper_teeth: 'normal', lower_teeth: 'normal' },
    image_useless: '',
    detection_info: [],
    annotator_feedback: ''
};
let isElementDraggable = false;
let alignerTagsData = [];

export default function ReportImageInDialogBox(props) {

    let rectangles = [];
    const navigate = useNavigate();
    const location = useLocation();
    const getImagesFromStore = useSelector(state => state.reportImage);
    const subFolder = props.filePath ? props.filePath : props.subFolderName;
    const imagePath = `${props.bucketName}/${subFolder}/${props?.imageData?.image}`;
    const fileFolderPath = `${props.bucketName}/${subFolder}`;

    const [open, setOpen] = useState(props.isDialogBoxOpen);
    const [locationData, setLocationData] = useState(location?.state?.data);
    const [rectanglesData, setRectanglesData] = useState([]);
    const [activeImage, setActiveImage] = useState(getImagesFromStore[imagePath]);

    // DOM elements
    const [screenshot, setScreenShot] = useState();
    const [boxes, setBoxes] = useState();
    const [imageTags, setImageTags] = useState({ zIndex: '9', display: 'none' });
    const [selectedImageTags, setSelectedImageTags] = useState([]);
    const [isShowSelectCategoryCard, setIsShowSelectCategoryCard] = useState(false);
    const [imageName, setImageName] = useState(props?.imageData?.image);
    const [editAndDeleteDropdown, setEditAndDeleteDropdown] = useState();
    const [stepsData, setStepsData] = useState();
    const [tagIndex, setTagIndex] = useState();
    const [isUseableImage, setIsUseableImage] = useState(true);
    const [snackbarData, setSnackbarData] = useState(null);
    const [isEditableTag, setIsEditableTag] = useState(false);
    const [detectionListData, setDetectionListData] = useState();
    const [showImageWithoutTags, setShowImageWithoutTags] = useState(false);
    const [isShowLabel, setIsShowLabel] = useState(true);
    const [feedbackValue, setFeedbackValue] = useState();
    const [missingTeethData, setMissingTeethData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alignerUser, setAlignerUser] = useState();
    const storeValue = useSelector(store => store.ui)
    const user = JSON.parse(localStorage.getItem('user'));
    const [filteredTags, setFilteredTags] = useState([]);
    const [legendFilters, setLegendFilters] = useState(['Cavity', 'Tartar', 'Enamel', 'Gum Recession', 'Gum Swelling', 'Broken Teeth', 'Gaps']);
    const [activeTool, setActiveTool] = useState();
    const [tagsList, setTagsList] = useState([]);
    const [tagsListArray, setTagsListArray] = useState([]);
    const [isLegendFilterApplied, setIsLegendFilterApplied] = useState(false);
    const [permissionData, setPermissionsData] = useState();
    const isSuperAnnotator = user?.user_type === USER_TYPE.SUPER_ANNOTATOR;

    // ref
    const imgRef = useRef();
    const marqueeRef = useRef();
    const dialogContainer = document.getElementsByClassName('MuiDialog-container')[0];
    const dialogHeight = dialogContainer?.offsetHeight - 320;
    const dialogWidth = dialogContainer?.offsetWidth / 2;

    // Temp variables
    let startX = 0;
    let startY = 0;
    const marqueeRect = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    };


    const handleClose = () => {
        props.handleCloseDialogBox(false);
        setOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarData(null);
    }

    const getFilesFromServer = async () => {
        GetBucketFile({ fileName: imageName, fileFolder: fileFolderPath }).then(async (resData) => {
            if (resData.data.data !== API_RESPONSE.NO_DATA) {
                // setActiveImage(resData.data.data);
                setActiveImage(getImagesFromStore[imagePath]);
                await getImageCoordinates()
            } else {
                setSnackbarData({ message: 'Image not found', severity: 'error' });
            }
            setIsLoading(false);
        })
            .catch((err) => {
                localStorage.clear();
                navigate('/');
            });
    }

    const getTagsList = async () => {
        try {
            const tagsListData = await getAssignedTagsAPI(`${props.subFolderName}/${imageName}`);
            if (tagsListData?.data?.data) {
                const tagsAndAccessData = tagsListData.data.data;
                setTagsList(tagsAndAccessData);
                // setTagsListArray(getTagsListArray(tagsAndAccessData));
                setTagsListArray(tagsAndAccessData.access_tags);
                setPermissionsData({
                    isImageUseableAccess: tagsAndAccessData.is_image_useable_access,
                    isDarkTeethAccess: tagsAndAccessData.is_dark_teeth_access,
                    isMissingTeethAccess: tagsAndAccessData.is_missing_teeth_access,
                    isPoorAlignmentAccess: tagsAndAccessData.is_poor_alignment_access,
                    isImageUselessAccess: tagsAndAccessData.is_image_useless_access,
                    isAnnotatorFeedbackAccess: tagsAndAccessData.is_annotator_feedback_access,
                    isDetectionInfoAccess: tagsAndAccessData.is_detection_info_access
                })
            }
        } catch (error) {
            localStorage.clear();
            navigate('/');
        }
    };

    const getAlignerUserDetails = async () => {
        try {
            const alignerData = await getAlignerUserById(locationData.user_id);
            setAlignerUser(alignerData.data.data)
        } catch (error) {
            localStorage.clear();
            navigate('/');
        }
    }

    useEffect(() => {
        imageTagsData = [];
        alignerTagsData = []; // For aligners
        setIsLoading(true);
        getFilesFromServer();
        getTagsList();

        if (user?.user_type === USER_TYPE.ALIGNER) {
            getAlignerUserDetails();
            detectionListFormData = {};
        } else {
            detectionListFormData = {
                // gaps_in_teeth: { upper_teeth: '', lower_teeth: '' },
                poor_alignment: { upper_teeth: [], lower_teeth: [] },
                dark_teeth: { upper_teeth: 'normal', lower_teeth: 'normal' },
                image_useless: '',
                detection_info: [],
                annotator_feedback: ''
            };
        }
        setDetectionListData(detectionListFormData);

    }, []);

    useEffect(() => {
        const domValue = document.querySelector.bind(document);
        // setActiveImage(setActiveReportImages(0));
        setScreenShot(domValue('#screenshot'));
        // setMarquee(domValue('#marquee'));
        setBoxes(domValue('#boxes'));
        // setCanvas(document.getElementById('canvas'));

        marqueeRef?.current?.classList.add('hide');
        if (boxes) {
            redraw();
        }
    }, [marqueeRef, screenshot]);

    function drawRegion() {
        if (activeTool === 'rectangle') {
            setActiveTool('');
            setFilteredTags(selectedImageTags);
            screenshot?.removeEventListener('pointerdown', startDrag);
            document.getElementById('draw').classList.remove('pointers')
            return;
        }
        setActiveTool('rectangle');
        screenshot?.addEventListener('pointerdown', startDrag);
        document.getElementById('draw').classList.add('pointers')
    }

    function startDrag(ev) {
        if (!boxes.innerHTML) {
            rectangles = [];
        }
        if (ev.button === 1) {
            const rect = hitTest(ev.layerX, ev.layerY);
            if (rect) {
                rectangles.splice(rectangles.indexOf(rect), 1);
                redraw();
            }
            return;
        }
        setTagIndex();
        window.addEventListener('pointerup', stopDrag);
        screenshot.addEventListener('pointermove', moveDrag);
        marqueeRef.current.classList.remove('hide');
        startX = ev.layerX;
        startY = ev.layerY;
        drawRect(marqueeRef, startX, startY, 0, 0);
        setFilteredTags([]);
        setIsLegendFilterApplied((prevState) => !prevState);
    }

    function stopDrag(ev) {
        marqueeRef.current.classList.add('hide');
        window.removeEventListener('pointerup', stopDrag);
        screenshot.removeEventListener('pointermove', moveDrag);
        if (!isElementDraggable) {
            if (ev.target === screenshot && marqueeRect.width && marqueeRect.height) {
                const lastTagValue = imageTagsData[imageTagsData.length - 1];
                if (marqueeRect.x !== lastTagValue?.x && marqueeRect.y !== lastTagValue?.y) {
                    rectangles.push(Object.assign({}, marqueeRect));
                    imageTagsData.push(Object.assign({}, marqueeRect));
                    setRectanglesData(imageTagsData);
                    redraw();

                    if (props.isAligner) {
                        const tags = [...alignerTagsData];
                        tags.push({
                            style: {
                                left: `${marqueeRect.x}px`, top: `${marqueeRect.y}px`,
                                width: `${marqueeRect.width}px`, height: `${marqueeRect.height}px`, border: `3px solid #5fbadd`, background: 'transparent',
                            },
                            nameStyle: { position: 'relative', top: '-2px', left: '0', background: '#5fbadd', color: '#5fbadd' },
                            type: '',
                            name: ''
                        });
                        alignerTagsData = tags;
                        setSelectedImageTags(tags);
                        setFilteredTags(tags);
                        boxes.innerHTML = '';
                    }
                    if (!props.isAligner) {
                        setIsShowSelectCategoryCard(true);
                        document.getElementById('draw').classList.remove('pointers');
                    }
                }
            }
        }
        isElementDraggable = false;
    }

    function moveDrag(ev) {
        let x = ev.layerX;
        let y = ev.layerY;
        let width = startX - x;
        let height = startY - y;
        if (width < 0) {
            width *= -1;
            x -= width;
        }
        if (height < 0) {
            height *= -1;
            y -= height;
        }
        Object.assign(marqueeRect, { x, y, width, height });
        drawRect(marqueeRef.current, marqueeRect);
    }

    function hitTest(x, y) {
        return rectangles.find(rect => (
            x >= rect.x &&
            y >= rect.y &&
            x <= rect.x + rect.width &&
            y <= rect.y + rect.height
        ));
    }

    function redraw() {
        boxes.innerHTML = '';
        rectangles.forEach((data) => {
            boxes.appendChild(drawRect(
                document.createElementNS("http://www.w3.org/2000/svg", 'rect'), data
            ));
        });
    }

    function drawRect(rect, data) {
        const { x, y, width, height } = data;
        if (x && y && width && height) {
            rect?.setAttributeNS(null, 'width', width);
            rect?.setAttributeNS(null, 'height', height);
            rect?.setAttributeNS(null, 'x', x);
            rect?.setAttributeNS(null, 'y', y);
            rect?.addEventListener('click', (event) => {
                // event.stopPropagation();
                // event.preventDefault();
                // console.log('rect', event.getAttributesNS('http://www.w3.org/2000/svg', 'width'));
            })
            return rect;
        }
    }


    function clearRect() {
        boxes.innerHTML = '';
        rectangles = [];
        setRectanglesData([]);
        setSelectedImageTags([]);
        // setImageTags({ display: 'none' });
        setIsShowSelectCategoryCard(false);
        imageTagsData = [];
        storeLogs(`Cleared all tags`)
    }

    function getSelectedTags(tag) {
        if (tag?.name) {
            const rectData = rectanglesData[rectanglesData.length - 1];
            const tags = [...selectedImageTags];
            if (tagIndex !== undefined) {
                storeLogs(`Tag edited from ${tags[tagIndex].name} to ${tag.name}`, {
                    x: rectanglesData[tagIndex].x,
                    y: rectanglesData[tagIndex].y,
                    w: rectanglesData[tagIndex].width,
                    h: rectanglesData[tagIndex].height
                })

                tags[tagIndex].name = `${tag.name}`;
                tags[tagIndex].style = {
                    left: `${rectanglesData[tagIndex].x}px`, top: `${rectanglesData[tagIndex].y}px`,
                    width: `${rectanglesData[tagIndex].width}px`, height: `${rectanglesData[tagIndex].height}px`, border: `3px solid ${tag.color}`, background: 'transparent',
                };
                tags[tagIndex].nameStyle = { position: 'relative', top: '-2px', left: '0', background: tag.color, color: tag.color };
                tags[tagIndex].type = TAGS_SHORT_NAME[tag.name]
            } else {
                tags.push({
                    name: `${tag.name}`,
                    style: {
                        left: `${rectData.x}px`, top: `${rectData.y}px`,
                        width: `${rectData.width}px`, height: `${rectData.height}px`, border: `3px solid ${tag.color}`, background: 'transparent',
                    },
                    nameStyle: { position: 'relative', top: '-2px', left: '0', background: tag.color, color: tag.color },
                    type: TAGS_SHORT_NAME[tag.name]
                });
                storeLogs(`${tag.name} created`, {
                    x: rectData.x,
                    y: rectData.y,
                    w: rectData.width,
                    h: rectData.height
                })
            }
            setSelectedImageTags(tags);
            setFilteredTags(tags);
        } else {
            if (tagIndex !== undefined && !isEditableTag) {
                imageTagsData.splice(tagIndex, 1);
            }
        };
        setTagIndex();
        setStepsData();
        // setImageTags({ display: 'none' });
        setIsShowSelectCategoryCard(false);
        document.getElementById('draw').classList.add('pointers');
        boxes.innerHTML = '';
    }

    function dragAndResizeBox(ev, item, index) {
        ev.target.style.border = `3px dotted ${item.nameStyle.background}`;
        ev.target.style.cursor = 'move';
        draggableDiv(index);
        ev.stopPropagation();
        const elem = document.getElementById('drawContainer');
        elem.addEventListener('click', () => {
            ev.target.style.border = item.style.border;
            ev.target.style.cursor = 'auto';
            elem.removeEventListener('click', () => { }, false);
        });
        ev.target.addEventListener('mousedown', (event) => {
            // mousedown(event, ev, item)
        });
    }

    function initDragElement(tagIndex) {
        isElementDraggable = true;
        var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
        var popups = [document.getElementsByClassName(`drag-tag${tagIndex}`)[0]]; // document.getElementsByClassName("popup");
        var elmnt = null;
        var currentZIndex = 100;

        for (var i = 0; i < popups.length; i++) {
            var popup = popups[i];
            var header = getHeader(popup);

            // eslint-disable-next-line no-loop-func
            popup.onmousedown = function () {
                this.style.zIndex = "" + ++currentZIndex;
            };

            if (header) {
                header.parentPopup = popup;
                header.onmousedown = dragMouseDown;
            }
        }

        function dragMouseDown(e) {
            elmnt = this.parentPopup;
            elmnt.style.zIndex = "" + ++currentZIndex;

            e = e || window.event;
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            // console.log('pos3, pos4', pos3, pos4);
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            if (!elmnt) {
                return;
            }

            e = e || window.event;
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:

            const tagWidth = Number(elmnt.style.width.replace('px', ''));
            const tagHeight = Number(elmnt.style.height.replace('px', ''));
            const boundWidth = imgRef.current.width - tagWidth;
            const boundHeight = imgRef.current.height - tagHeight;

            if (elmnt.offsetLeft + tagWidth >= imgRef.current.width) {
                elmnt.style.left = boundWidth + "px";
            }
            if (elmnt.offsetLeft <= 0) {
                elmnt.style.left = pos1 * 0 + "px";
            }
            if (elmnt.offsetLeft + tagWidth <= imgRef.current.width || elmnt.offsetLeft >= 0) {
                elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
            }

            if (elmnt.offsetTop + tagHeight >= imgRef.current.height) {
                elmnt.style.top = boundHeight + "px";
            }

            if (elmnt.offsetTop <= 0) {
                elmnt.style.top = pos1 * 0 + "px";
            }

            if (elmnt.offsetTop + tagHeight <= imgRef.current.width || elmnt.offsetTop >= 0) {
                elmnt.style.top = elmnt.offsetTop - pos2 + "px";
            }

            // elmnt.style.top = elmnt.offsetTop - pos2 + "px";
            // elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
            imageTagsData[tagIndex].x = Number(elmnt.style.left.split('px')[0]);
            imageTagsData[tagIndex].y = Number(elmnt.style.top.split('px')[0]);
            setSelectedImageTags((prevState) => {
                const styleObj = { ...prevState[tagIndex].style };
                styleObj.top = elmnt.style.top;
                styleObj.left = elmnt.style.left;
                prevState[tagIndex].style = styleObj;
                setFilteredTags(prevState);
                storeLogs(`${prevState[tagIndex].name} moved`, {
                    x: imageTagsData[tagIndex].x,
                    y: imageTagsData[tagIndex].y,
                    w: Number(imageTagsData[tagIndex].width.toFixed(0)),
                    h: Number(imageTagsData[tagIndex].height.toFixed(0))
                })
                return prevState;
            });
            // console.log('elmnt.style', elmnt.style.left, elmnt.style.top);
        }

        function getHeader(element) {
            var headerItems = element.getElementsByClassName("drag-tag-box");

            if (headerItems.length === 1) {
                return headerItems[0];
            }

            return null;
        }
    }

    function initResizeElement(tagIndex) {
        var popups = document.getElementsByClassName("popup");
        var element = null;
        var startX, startY, startWidth, startHeight;

        for (var i = 0; i < popups.length; i++) {
            var p = popups[i];

            var right = document.createElement("div");
            right.className = "resizer-right";
            p.appendChild(right);
            right.addEventListener("mousedown", initDrag, false);
            right.parentPopup = p;

            var bottom = document.createElement("div");
            bottom.className = "resizer-bottom";
            p.appendChild(bottom);
            bottom.addEventListener("mousedown", initDrag, false);
            bottom.parentPopup = p;

            var both = document.createElement("div");
            both.className = "resizer-both";
            p.appendChild(both);
            both.addEventListener("mousedown", initDrag, false);
            both.parentPopup = p;
        }

        function initDrag(e) {
            element = this.parentPopup;

            startX = e.clientX;
            startY = e.clientY;
            startWidth = parseInt(
                document.defaultView.getComputedStyle(element).width,
                10
            );
            startHeight = parseInt(
                document.defaultView.getComputedStyle(element).height,
                10
            );
            document.documentElement.addEventListener("mousemove", doDrag, false);
            document.documentElement.addEventListener("mouseup", stopDrag, false);
        }

        function doDrag(e) {
            element.style.width = startWidth + e.clientX - startX + "px";
            element.style.height = startHeight + e.clientY - startY + "px";
        }

        function stopDrag() {
            document.documentElement.removeEventListener("mousemove", doDrag, false);
            document.documentElement.removeEventListener("mouseup", stopDrag, false);
            // updating image tags
            const getElementStyle = element.style;
            imageTagsData[tagIndex].x = Number(getElementStyle.left.split('px')[0]);
            imageTagsData[tagIndex].y = Number(getElementStyle.top.split('px')[0]);
            imageTagsData[tagIndex].width = Number(getElementStyle.width.split('px')[0]);
            imageTagsData[tagIndex].height = Number(getElementStyle.height.split('px')[0]);
            setSelectedImageTags((prevState) => {
                const styleObj = { ...prevState[tagIndex].style };
                styleObj.width = getElementStyle.width;
                styleObj.height = getElementStyle.height;
                prevState[tagIndex].style = styleObj;
                setFilteredTags(prevState);
                storeLogs(`${prevState[tagIndex].name} Resized`, {
                    x: imageTagsData[tagIndex].x,
                    y: imageTagsData[tagIndex].y,
                    w: Number(imageTagsData[tagIndex].width.toFixed(0)),
                    h: Number(imageTagsData[tagIndex].height.toFixed(0))

                })
                return prevState;
            });

        }
    }

    function draggableDiv(tagIndex) {
        initDragElement(tagIndex);
        initResizeElement(tagIndex);
    }

    const getTagName = (key, data) => {
        return key === 'cavity' ? data.type ? data.type : 'C1' : GetShortName(key, '_')
    }

    async function getImageCoordinates() {
        const isReviewer = user.user_type === USER_TYPE.REVIEWER || props.isGapsInFrontTeethReports || isSuperAnnotator ? true : false;
        let isReviewerDataAvailable = false;
        const trimSubFolder = `${props.subFolderName.split('/').join(':')}`.trim();
        if (isReviewer) {

            const getFileName = props.isThirdPartyReports ? `${trimSubFolder}:${removeExtension(imageName)}-reviewer.json` : `${trimSubFolder}:${removeExtension(imageName)}-reviewer.json`
            const reviewerJsonData = await GetBucketFile({ fileName: getFileName, fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}` });
            const tagsData = reviewerJsonData.data.data;
            if (tagsData !== 'No Data') {
                if (props.isGapsInFrontTeethReports && (tagsData?.gaps_in_front_teeth || tagsData?.gaps_in_teeth ||
                    !tagsData?.poor_alignment?.upper_teeth || !tagsData?.poor_alignment?.lower_teeth ||
                    typeof tagsData?.poor_alignment?.upper_teeth === 'string' || typeof tagsData?.poor_alignment?.lower_teeth === 'string')) {
                    const imageJSONData = reviewerJsonData.data.data;
                    const poorAlignmentData = { poor_alignment: { upper_teeth: [], lower_teeth: [] } };
                    poorAlignmentData.poor_alignment.upper_teeth = !imageJSONData?.poor_alignment?.upper_teeth ? [] : imageJSONData.poor_alignment.upper_teeth;
                    poorAlignmentData.poor_alignment.lower_teeth = !imageJSONData?.poor_alignment?.lower_teeth ? [] : imageJSONData.poor_alignment.lower_teeth;
                    await getJsonTagsData({ ...imageJSONData, ...poorAlignmentData }, true);
                } else {
                    await getJsonTagsData(reviewerJsonData.data.data);
                }
                isReviewerDataAvailable = true;
            }
        }
        if (!props.isThirdPartyReports && !isReviewerDataAvailable && user.user_type !== USER_TYPE.ALIGNER) {
            const annotatorJsonData = await GetBucketFile({ fileName: `${removeExtension(imageName)}.json`, fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}` });
            const tagsData = annotatorJsonData.data.data;
            await getJsonTagsData(tagsData, isReviewer);
        }

        // User, who has access of third-party reports
        if (props.isThirdPartyReports) {
            const annotatorJsonData = await GetBucketFile({ fileName: `${trimSubFolder}:${removeExtension(imageName)}-reviewer.json`, fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}` });
            const tagsData = annotatorJsonData.data.data;
            await getJsonTagsData(tagsData, false);
        }

        if (user.user_type === USER_TYPE.ALIGNER) {
            const annotatorJsonData = await GetBucketFile({ fileName: `${removeExtension(imageName)}-aligner.json`, fileFolder: `${props.JSONBucketName}/${props.JSONFolderName}` });
            const tagsData = annotatorJsonData.data.data;
            await getJsonTagsData(tagsData, isReviewer);
        }

    }

    async function getJsonTagsData(tagsData, isReviewer = false) {
        let imageJSONDataTags = [];
        let coordinatesData = [];
        if (tagsData !== API_RESPONSE.NO_DATA) {
            for (let key in tagsData) {
                if (Array.isArray(tagsData[key]) && key !== IMAGE_TAGS.MISSING_TEETH) {
                    const getCoordinatesData = imageCoordinatesCalculation(key, tagsData);
                    imageJSONDataTags = [...imageJSONDataTags, ...getCoordinatesData.imageTags];
                    coordinatesData = [...coordinatesData, ...getCoordinatesData.coordinatesData];
                } else {
                    if (Array.isArray(tagsData[key]) && props?.isAligner) {
                        const getCoordinatesData = imageCoordinatesCalculation(key, tagsData);
                        imageJSONDataTags = [...imageJSONDataTags, ...getCoordinatesData.imageTags];
                        coordinatesData = [...coordinatesData, ...getCoordinatesData.coordinatesData];
                    }
                }
            }
            setSelectedImageTags(imageJSONDataTags);
            setFilteredTags(imageJSONDataTags);
            setRectanglesData(coordinatesData);
            imageTagsData = coordinatesData;
            detectionListFormData = {
                ...detectionListFormData,
                ...tagsData
            };
            setDetectionListData(detectionListFormData);
            if (isReviewer) {
                // saveImageTags(imageJSONDataTags, true);
            }
        } else {
            if (user.user_type !== USER_TYPE.ALIGNER) {
                await getTagsFromTxtFile();
            }
            if (user.user_type === USER_TYPE.ALIGNER && tagsData === 'No Data') {
                // saveImageTags(imageJSONDataTags, true);
            }
        }

        storeMissingTeethToLocalStorage(tagsData);
    }

    function imageCoordinatesCalculation(key, tagsData) {
        const imgNaturalWidth = imgRef.current.naturalWidth;
        const imgNaturalHeight = imgRef.current.naturalHeight;
        const renderImageWidth = imgRef.current.width;
        const renderImageHeight = imgRef.current.height;
        const imageTags = [...selectedImageTags];
        const coordinatesData = [];
        tagsData[key].forEach((item) => {
            let { x, y, w, h } = item;
            x = (x * renderImageWidth) / imgNaturalWidth;
            y = (y * renderImageHeight) / imgNaturalHeight;
            w = (w * renderImageWidth) / imgNaturalWidth;
            h = (h * renderImageHeight) / imgNaturalHeight;
            coordinatesData.push({ x, y, width: w, height: h });
            const shortName = getTagName(key, item);
            const colorCode = TAGS_COLOR_CODE[shortName] || '#5fbadd';
            imageTags.push({
                name: shortName,
                style: {
                    left: `${x}px`, top: `${y}px`,
                    width: `${w}px`, height: `${h}px`,
                    border: `3px solid ${colorCode}`, background: 'transparent'
                },
                nameStyle: {
                    position: 'relative',
                    top: '-2px',
                    left: '0',
                    background: colorCode,
                    color: colorCode
                },
                type: TAGS_SHORT_NAME[shortName]
            });
        });
        return {
            coordinatesData,
            imageTags
        };
    }

    function storeMissingTeethToLocalStorage(tagsData) {
        // Storing missing teeth data to a local storage
        const getTeethFromLocalStorage = JSON.parse(localStorage.getItem('teeth'));
        const getMissingTeeth = tagsData?.missing_teeth?.length > 0 ? tagsData.missing_teeth : [];
        let teethData;
        if (getTeethFromLocalStorage) {
            teethData = [...getTeethFromLocalStorage, ...getMissingTeeth]
        } else {
            teethData = [...props.missingTeeth, ...getMissingTeeth];
        }
        setMissingTeethData([...new Set(teethData)]);
        // localStorage.setItem('teeth', JSON.stringify([...new Set(teethData)]));
    }

    async function getTagsFromTxtFile() {
        let txtFileName;
        if (props.isAnnotatedReports) {
            txtFileName = `${removeExtension(imageName)}.txt`;
        } else {
            const splitFolder = props.subFolderName.split('/');
            const txtSubFolder = splitFolder.length > 0 ? splitFolder[splitFolder.length - 1] : props.subFolderName;
            // console.log("subFolder", props.subFolderName);
            txtFileName = `${txtSubFolder}_${removeExtension(imageName)}.txt`;
            // txtFileName = `${removeExtension(imageName)}.txt`;
        }
        const txtData = await GetBucketFile({ fileName: txtFileName, fileFolder: `${props.bucketName}/${props.txtFolderName}` });
        if (txtData) {
            tagsCalculation(txtData);
        }
    }

    const GetTagNameAndColorCode = (value) => {
        switch (value) {
            case 0:
                return {
                    tagName: 'C1',
                    colorCode: TAGS_COLOR_CODE['C1']
                }
            case 1:
                return {
                    tagName: 'G',
                    colorCode: TAGS_COLOR_CODE['G']
                }
            case 2:
                return {
                    tagName: 'GR',
                    colorCode: TAGS_COLOR_CODE['GR']
                }
            case 3:
                return {
                    tagName: 'GS',
                    colorCode: TAGS_COLOR_CODE['GS']
                }
            case 4:
                return {
                    tagName: 'T',
                    colorCode: TAGS_COLOR_CODE['T']
                }
            default:
                return {};

        }
    }



    function tagsCalculation(tags) {
        const coordinates = [];
        const imgWidth = imgRef.current?.width;
        const imgHeight = imgRef.current?.height;
        const imageTags = [...selectedImageTags];

        const tagsData = tags.data.data.split('\n');
        tagsData.forEach((data, index) => {
            const splitCoordinates = data.split(' ').slice(1, 5);
            if (splitCoordinates.length > 1) {
                const { x, y, w, h } = {
                    x: Number(splitCoordinates[0]),
                    y: Number(splitCoordinates[1]),
                    w: Number(splitCoordinates[2]),
                    h: Number(splitCoordinates[3])
                };
                let x_min = (x - w / 2) * imgWidth;
                let x_max = (x + w / 2) * imgWidth;
                let y_min = (y - h / 2) * imgHeight;
                let y_max = (y + h / 2) * imgHeight;
                coordinates.push({
                    x: x_min,
                    y: y_min,
                    width: x_max - x_min,
                    height: y_max - y_min
                });
                // const colorCode = Number(data.split(' ')[0]) === 1 ? '#5fbadd' : 'red';
                // const tagName = Number(data.split(' ')[0]) === 1 ? 'T' : 'C1';
                const tagValue = Number(data.split(' ')[0]);
                const { tagName, colorCode } = GetTagNameAndColorCode(tagValue);

                imageTags.push({
                    name: tagName,
                    style: {
                        left: `${x_min}px`, top: `${y_min}px`,
                        width: `${x_max - x_min}px`, height: `${y_max - y_min}px`,
                        border: `3px solid ${colorCode}`, background: 'transparent'
                    },
                    nameStyle: {
                        position: 'relative',
                        top: '-2px',
                        left: '0',
                        background: colorCode,
                        color: colorCode
                    },
                    type: TAGS_SHORT_NAME[tagName]
                });
                setSelectedImageTags(imageTags);
                setFilteredTags(imageTags);
                setRectanglesData(coordinates);
                imageTagsData = coordinates;
            }
        });
        // if (props.imageData.imageType !== TEETH_TYPES.FRONT_TEETH) {
        //     detectionListFormData = {};
        //     setDetectionListData(detectionListFormData);
        // }
        // saveImageTags(imageTags, true);
    }

    function handleEditAndDeleteTag(event, index) {
        event.stopPropagation();
        setTagIndex(index);
        const eleStyle = { ...imageTagsData[index] };
        eleStyle.top = `${eleStyle.y + 25}px`;
        eleStyle.left = `${eleStyle.x}px`;
        setEditAndDeleteDropdown(eleStyle);
        imgRef.current.addEventListener('click', () => {
            setEditAndDeleteDropdown();
        })

    }

    function handleEditTag() {
        setIsEditableTag(true);
        setStepsData(selectedImageTags[tagIndex]);
        // setFilteredTags(selectedImageTags[tagIndex]);
        setIsShowSelectCategoryCard(true);
        setEditAndDeleteDropdown();
    }

    function handleDeleteTag() {
        storeLogs(`${selectedImageTags[tagIndex].name} deleted`, {
            x: imageTagsData[tagIndex].x,
            y: imageTagsData[tagIndex].y,
            w: imageTagsData[tagIndex].width,
            h: imageTagsData[tagIndex].height
        })
        imageTagsData.splice(tagIndex, 1);
        const imageTags = [...selectedImageTags];
        imageTags.splice(tagIndex, 1);
        setSelectedImageTags(imageTags);
        setFilteredTags(imageTags)
        setEditAndDeleteDropdown();

        if (user?.user_type === USER_TYPE.ALIGNER) {
            alignerTagsData.splice(tagIndex, 1);
        }

    }

    function handleSwitchChange(event) {
        setIsUseableImage(event.target.checked)
    }

    const getJSONFIleName = () => {
        if (props.isThirdPartyReports) {
            const trimSpace = `${props.subFolderName.split('/').join(':')}`.trim();
            return `${trimSpace}:${removeExtension(imageName)}-reviewer.json`;
        }
        if (props.isGapsInFrontTeethReports || isSuperAnnotator) {
            const subFolder = props.subFolderName.split('/').join(':');
            return `${subFolder}:${removeExtension(imageName)}-reviewer.json`;
        }
        switch (user?.user_type) {
            case USER_TYPE.ANNOTATOR:
                return `${removeExtension(imageName)}.json`;
            case USER_TYPE.REVIEWER:
                return `${removeExtension(imageName)}-reviewer.json`;
            case USER_TYPE.ALIGNER:
                return `${removeExtension(imageName)}-aligner.json`;
            default:
                return;
        }
    }

    const getInitialJSONData = () => {
        const sampleJSON = {
            image_name: !locationData?.isThirdPartyReports ? `${props?.subFolderName}/${imageName}` : `${props?.subFolderName}/${props?.imageData?.image}`,
            image_type: !locationData?.isThirdPartyReports ? props?.imageData?.imageType : '',
            image_useable: { is_image_useable: isUseableImage, reason: '' },
        }
        if (props.isAligner) {
            return {
                ...sampleJSON,
                aligner_tags: [],
            }
        }
        return {
            ...sampleJSON,
            cavity: [],
            tartar: [],
            gum_recession: [],
            gum_swelling: [],
            broken_teeth: [],
            enamel: [],
            gaps: [],
            missing_teeth: [],
        }
    }

    const saveImageAnnotation = (formattedJsonData) => {
        const { bucketName, subFolderName } = props;
        const imageAnnotatedData = {
            bucket: bucketName,
            folder: subFolderName,
            image_name: imageName,
            annotator: user?._id,
            tags_data: formattedJsonData,
            is_image_useable: formattedJsonData?.image_useable?.is_image_useable
        }
        saveImageAnnotationData(imageAnnotatedData)
            .then((response) => {
                setSnackbarData({ message: 'Successfully stored data', severity: 'success' });
                detectionListFormData = {};
                setTimeout(() => {
                    props.handleCloseDialogBox({ [imageName]: true });
                    setOpen(false);
                    setIsLoading(false);
                }, 1000);
            })
            .catch((err) => {
                localStorage.clear();
                navigate('/');
            })
    }

    function saveImageTags(imageTags = [], isInitialSave = false) {
        // Get JSON file name
        const jsonFileName = getJSONFIleName();
        // Get JSON initial data
        const jsonData = getInitialJSONData();

        imageTags = imageTags.length > 0 ? imageTags : selectedImageTags;
        const formattedJsonData = formatJsonData(jsonData, imageTags);
        setIsLoading(true);
        if (formattedJsonData && user?.user_type !== 'admin') {
            // console.log("formattedJsonData yes", formattedJsonData, jsonFileName);
            const dataObj = {
                data: formattedJsonData,
                parentFolder: props.JSONBucketName,
                subFolder: props.JSONFolderName,
                jsonFileName,
                feedback: feedbackValue,
                reportId: props.reportId,
                reportType: getReportType(props)
            }
            StoreJsonFile(dataObj)
                .then((response) => {
                    if (!isInitialSave) {
                        // saveImageAnnotation(formattedJsonData);
                        setSnackbarData({ message: 'Successfully stored data', severity: 'success' });
                        detectionListFormData = {};
                        setTimeout(() => {
                            props.handleCloseDialogBox(true);
                            setIsLoading(false);
                            setOpen(false);
                        }, 1000);
                    }
                })
                .catch((err) => {
                    localStorage.clear();
                    navigate('/');
                })

        }
        return;
    }

    function formatJsonData(jsonData, imageTags) {
        const imgNaturalWidth = imgRef.current.naturalWidth;
        const imgNaturalHeight = imgRef.current.naturalHeight;
        const renderImageWidth = imgRef.current.width;
        const renderImageHeight = imgRef.current.height;

        imageTags.forEach((item, index) => {
            let { x, y, width: w, height: h } = imageTagsData[index];
            // console.log((x*480)/752, (y*328)/512, (w*480)/752, (h*328)/512);
            x = ((x * imgNaturalWidth) / renderImageWidth).toFixed(6);
            y = ((y * imgNaturalHeight) / renderImageHeight).toFixed(6);
            w = ((w * imgNaturalWidth) / renderImageWidth).toFixed(6);
            h = ((h * imgNaturalHeight) / renderImageHeight).toFixed(6);

            let teethData = [];
            switch (item.name) {
                case 'C1':
                case 'C2':
                case 'C3':
                case 'C':
                    teethData = jsonData.cavity?.length > 0 ? [...jsonData.cavity] : [];
                    teethData.push({ x, y, w, h, type: item.name });
                    jsonData.cavity = teethData;
                    break;
                case 'E':
                    teethData = jsonData.enamel?.length > 0 ? [...jsonData.enamel] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.enamel = teethData;
                    break;
                case 'BT':
                    teethData = jsonData.broken_teeth?.length > 0 ? [...jsonData.broken_teeth] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.broken_teeth = teethData;
                    break;
                case 'T':
                    teethData = jsonData.tartar?.length > 0 ? [...jsonData.tartar] : [];
                    teethData.push({ x, y, w, h })
                    jsonData.tartar = teethData;
                    break;
                case 'GR':
                    teethData = jsonData.gum_recession?.length > 0 ? [...jsonData.gum_recession] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gum_recession = teethData;
                    break;
                case 'GS':
                    teethData = jsonData.gum_swelling?.length > 0 ? [...jsonData.gum_swelling] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gum_swelling = teethData;
                    break;
                case 'G':
                    teethData = jsonData.gaps?.length > 0 ? [...jsonData.gaps] : [];
                    teethData.push({ x, y, w, h });
                    jsonData.gaps = teethData;
                    break;
                default:
                    // This is for aligner
                    if (props.isAligner) {
                        teethData = jsonData.aligner_tags?.length > 0 ? [...jsonData.aligner_tags] : [];
                        teethData.push({ x, y, w, h });
                        jsonData.aligner_tags = teethData;
                    }
                    break;
            }
        });
        if (props.isGapsInFrontTeethReports) {
            delete detectionListFormData.gaps_in_front_teeth;
            delete detectionListFormData.gaps_in_teeth;
            // delete detectionListFormData.poor_alignment;
            // delete detectionListFormData.dark_teeth;
        }
        if (props.isAligner) {
            return {
                ...jsonData,
                image_useable: detectionListFormData?.image_useable ? detectionListFormData.image_useable : jsonData.image_useable,
                width: imgNaturalWidth,
                height: imgNaturalHeight
            }
        }
        return {
            ...detectionListFormData,
            ...jsonData,
            image_useable: detectionListFormData?.image_useable ? detectionListFormData.image_useable : jsonData.image_useable,
            missing_teeth: detectionListFormData?.missing_teeth ? detectionListFormData.missing_teeth : jsonData.missing_teeth,
            width: imgNaturalWidth,
            height: imgNaturalHeight,
            annotator_feedback: feedbackValue ? feedbackValue : detectionListFormData?.annotator_feedback ? detectionListFormData.annotator_feedback : ''
        };
    }

    const handleDetectionFormValues = (formValues) => {
        switch (formValues?.name) {
            case IMAGE_TAGS.IMAGE_USEABLE:
                detectionListFormData.image_useable = formValues.value
                break;
            case IMAGE_TAGS.MISSING_TEETH:
                detectionListFormData.missing_teeth = formValues.value;
                setMissingTeethData([...formValues.value]);
                break;
            // case IMAGE_TAGS.GAPS_IN_TEETH:
            //     detectionListFormData.gaps_in_teeth = formValues.value;
            //     break;
            case IMAGE_TAGS.POOR_ALIGNMENT:
                detectionListFormData.poor_alignment = formValues.value;
                break;
            case IMAGE_TAGS.DARK_TEETH:
                detectionListFormData.dark_teeth = formValues.value;
                break;
            case IMAGE_TAGS.IMAGE_USELESS:
                detectionListFormData.image_useless = formValues.value;
                break;
            case IMAGE_TAGS.DETECTION_INFO:
                detectionListFormData.detection_info = formValues.value;
                break;
            default:
                break;
        }
    }

    const handleFeedbackValue = (value) => {
        setFeedbackValue(oldState => {
            return value
        });
    }

    const handleLegendsListChanges = (listData) => {
        if (listData?.status) {
            const tagsArray = [...selectedImageTags];
            const data = tagsArray.filter((item) => item.type === listData.type);
            setFilteredTags((prevState) => legendFilters.length > 1 ? [...prevState, ...data] : data);
            setLegendFilters((prevState) => {
                prevState.push(listData.type);
                return prevState;
            });
        } else {
            const tagsArray = [...filteredTags];
            const data = tagsArray.filter((item) => item.type !== listData.type);
            const filters = legendFilters.filter(item => item !== listData.type);
            setFilteredTags(data);
            setLegendFilters(filters);
        }
    }

    const storeLogs = (message, coordinate = null) => {
        postLogDetails({
            image: imagePath,
            payLoad: message,
            imageData: {
                image: imagePath,
                coordinate
            }
        })
    }

    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <PositionedSnackbar
                isOpenSnackbar={snackbarData?.message ? true : false}
                severity={snackbarData?.severity}
                message={snackbarData?.message}
                onCloseSnackBar={handleSnackbarClose}
            />
            <DialogTitle>
                <div className='text-end'>
                    <CloseIconButton handleClose={handleClose} />
                </div>
                {/* Tool header */}
                {
                    isLoading ? <div className='text-center'>Loading data...</div> :
                        <ToolHeader
                            drawRegion={drawRegion}
                            clearRect={clearRect}
                            showImageWithoutTags={showImageWithoutTags}
                            handleImageWithoutTags={() => {
                                setActiveTool('box');
                                setShowImageWithoutTags(prevState => !prevState)
                            }}
                            isShowLabel={isShowLabel}
                            handleLabel={() => {
                                setActiveTool('label');
                                setIsShowLabel(prevState => !prevState)
                            }}
                            isAligner={props.isAligner}
                            activeTool={activeTool}
                            currentAligner={alignerUser ? alignerUser?.current_aligner : ""}
                            checkedAligner={locationData?.aligner_number}
                        />
                }
            </DialogTitle>
            <DialogContent>
                <div className='row position-relative'>
                    <div className='col-6'>
                        {/* <TestingZoomFeature image={activeImage} /> */}
                        <div>
                            <div className='position-relative' id="drawContainer">
                                {!activeImage && <Skeleton variant="rectangular" with="100%" height={500} />}
                                <img
                                    // width={752}
                                    height={dialogHeight} //612
                                    className={`w-100 ${activeImage ? 'd-inline-block' : 'd-none'}`}
                                    src={activeImage ? activeImage : ImagePlaceholder}
                                    id="screenshot"
                                    alt="report"
                                    draggable="false"
                                    ref={imgRef}
                                    style={{ filter: `contrast(${storeValue.contrast}%) brightness(${storeValue.brightness}%)` }}
                                />
                                {
                                    imgRef?.current && (
                                        <svg height={imgRef?.current?.height}
                                            viewBox={`0 0 ${imgRef?.current?.width} ${imgRef?.current?.height}`}
                                            id="draw"
                                            className='pointers'
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect id="marquee" x="450" y="420" width="150" height="150" ref={marqueeRef} />
                                            <g id="boxes"></g>
                                        </svg>
                                    )
                                }
                                {/* <TagLabel /> */}
                                {
                                    !showImageWithoutTags && (
                                        <>
                                            {
                                                filteredTags.map((item, index) => (
                                                    checkTagsAccess(item.name, tagsList, user?.user_type) &&
                                                    <div
                                                        key={index}
                                                        className={`popup tag-name border-0 drag-tag${index}`}
                                                        style={item.style}
                                                        onClick={(event) => dragAndResizeBox(event, item, index)}
                                                    >
                                                        <div className='drag-tag-box h-100' style={{ border: item.style.border }}>
                                                            {
                                                                isShowLabel && (
                                                                    <div className='d-inline-flex ps-1 font-size-14 popup-header' style={item.nameStyle}
                                                                        onClick={($event) => handleEditAndDeleteTag($event, index)}>
                                                                        <span className='text-white text-capitalize'>{item.name}</span>
                                                                        <span>
                                                                            <ArrowDropDownIcon className='primary-bg text-white'
                                                                                style={{ width: '20px', background: item.nameStyle.background, height: 'auto' }} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                            {
                                                editAndDeleteDropdown && (
                                                    <div className='position-absolute edit-and-delete-tag' style={editAndDeleteDropdown}>

                                                        {
                                                            user?.user_type !== USER_TYPE.ALIGNER && (
                                                                <Button className='p-0 font-size-13 text-capitalize cursor-pointer
                                                                justify-content-start primary-black'
                                                                    onClick={handleEditTag}>
                                                                    <ModeEditIcon className='edit-icon me-1' /> Edit</Button>
                                                            )
                                                        }

                                                        <Button className='p-0  font-size-13 text-capitalize cursor-pointer justify-content-start primary-black'
                                                            onClick={handleDeleteTag}>
                                                            <DeleteIcon className='edit-icon me-1' /> Delete</Button>
                                                    </div>
                                                )
                                            }

                                            {
                                                isShowSelectCategoryCard && (
                                                    <div className='position-absolute select-tags-card'>
                                                        <BasicCard
                                                            onSelectedTags={getSelectedTags}
                                                            stepsData={stepsData}
                                                            tagIndex={tagIndex}
                                                            tagsList={tagsListArray} />
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }

                            </div>
                        </div>

                        {
                            !props.isAligner && (
                                <div className='row' style={{ marginTop: `${imgRef.current?.height}px` }}>
                                    <div className='col-12'>
                                        <LegendsList
                                            handleLegendsListChanges={handleLegendsListChanges}
                                            isLegendFilterApplied={isLegendFilterApplied}
                                            tagsList={tagsListArray} />
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className='col-6'>
                        {!isLoading && props.isAligner && imgRef?.current?.height &&
                            <AlignerVideoIframe
                                heightSize={imgRef?.current?.height}
                                alignerUser={alignerUser}
                            />}
                        {isLoading && props.isAligner && <Skeleton variant="rectangular" with="100%" height={500} />}
                        {/* Detection list form */}
                        {
                            // <div className='text-center'><CircularProgress style={{ width: '100px', height: '100px' }} /></div>
                        }
                        <DetectionListForm
                            imageType={props?.imageData?.imageType}
                            handleDetectionFormValues={handleDetectionFormValues}
                            detectionListFormData={detectionListFormData}
                            isReviewer={user?.user_type === 'reviewer' ? true : false}
                            handleFeedbackValue={handleFeedbackValue}
                            missingTeethData={missingTeethData}
                            appDetails={locationData?.app}
                            isAligner={props?.isAligner}
                            permissionData={permissionData}
                        />
                    </div>
                </div>
                <hr />
            </DialogContent>
            <DialogActions className='mb-3'>
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={saveImageTags}>
                    {isLoading ? <CircularProgress className='text-white' style={{ width: '25px', height: '25px' }} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}