import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";

const ImageUselessSection = (props) => {

    const { handleImageUselessChanges, imageUselessData } = props;

    return (
        <div className='my-1 d-flex align-items-center'>
            <label className='font-size-14 me-2'><b>Image useless:</b></label>
            <FormControl component="fieldset" variant="standard">
                <FormGroup className='d-flex flex-column'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={($event) => handleImageUselessChanges($event)}
                                name="repeat"
                                size="small"
                                checked={imageUselessData ? true : false}
                            />
                        }
                        label="Repeat"
                    />
                </FormGroup>
            </FormControl>
        </div>
    )
}

export default ImageUselessSection;